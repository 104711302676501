import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import 'rsuite/dist/rsuite-no-reset.min.css';
import 'styles/main.scss';
import 'styles/fonts.css';
import { whichDomain } from 'helpers/which-domain';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

console.log('App Version:', whichDomain(), process.env.REACT_APP_VERSION, process.env.REACT_APP_VERSION_BUILD);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register({
//   onUpdate(registration) {
//     console.log('serviceWorkerRegistration', 'onUpdate', registration);
//     registration.waiting?.postMessage('SKIP_WAITING');
//   }
// });
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
