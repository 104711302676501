import { PERMISSION } from './user';
import { RuleType } from './rule';
import { CustomDate, PositionType, StandardBreakType } from '@types';
import { SiteType } from './site';
import { AvailabilitySchema } from 'components/my-calendar/modal/availability';
import { ManagerSchema, StaffBaseRateType } from 'components/staff/modal/add/schema';

export enum LEAVE_STATUS {
  APPROVED = 'APPROVED',
  REQUESTED = 'REQUESTED',
  DENIED = 'DENIED'
}
export enum EMPLOYMENT_TYPE {
  BY_FIXED_TIME = 'BY_FIXED_TIME',
  BY_PART_TIME = 'BY_PART_TIME'
}

export enum LEAVE_REASON {
  annual_leave = 'Annual leave',
  community_leave = 'Community service leave',
  comp_leave = 'Compassionate & bereavement',
  family_leave = 'Family & domestic violence leave',
  leave_without_pay = 'Leave without pay',
  long_leave = 'Long service leave',
  maternity_leave = 'Maternity & parental leave',
  sick_leave = "Sick & carer's leave",
  time_off_in_lieu = 'Time Off In Lieu',
  jury_duty_leave = 'Jury Duty Leave',
  bereavement_leave = 'Bereavement leave',
  workers_leave = 'Workers compensation'
}

export interface LeaveType {
  dateStatusChanged: CustomDate | string;
  dateUpdated: CustomDate | string;
  date_created: string;
  end_day: string;
  exportLeaveTypeId: string | undefined;
  exported: '1' | '0';
  id: number;
  days: number;
  isExported: boolean;
  isShouldExport: boolean;
  isPast: boolean;
  leaveApplicationId: string | null;
  leaveType: keyof typeof LEAVE_REASON;
  leaveTypeLabel: LEAVE_REASON;
  reason: string;
  shouldExport: '1' | '0';
  start_day: string;
  status: LEAVE_STATUS;
  staff: Pick<StaffType, 'avatar' | 'email' | 'employmentType' | 'firstName' | 'id' | 'isXeroSynced' | 'lastName'>;
  units: number;
  start: CustomDate;
  finish: CustomDate;
  manager?: Pick<StaffType, 'avatar' | 'email' | 'employmentType' | 'firstName' | 'id' | 'lastName'>;
}

export type StaffType = {
  availability: AvailabilitySchema[];
  avatar: string | null;
  baseRateType: StaffBaseRateType;
  dateOfBirth: string | null;
  email: string;
  firstName: string;
  hasRates: boolean;
  hasRules: boolean;
  hoursAvailable: string;
  id: number;
  avgRate: number;
  lastLogin: CustomDate | null;
  lastLoginDate: string | null;
  lastName: string;
  empNum?: string;
  leave: LeaveType;
  mobileNumber: string | null;
  mobileNumberCountryCode: string | null;
  mobileNationalNumber?: string | null;
  payrollType: string | null;
  permission: PERMISSION;
  positions: PositionType[];
  salaryEmployee: boolean;
  salaryMaxHours: string;
  setupProccess: string;
  sites: SiteType[];
  status: string;
  yourNumber: string | null;
  isXeroSynced: boolean;
  employmentType: EMPLOYMENT_TYPE;
  fixedEmploymentData: FixedEmploymentDataType;
  isAvailable: boolean;
  isPartialAvailable: boolean;
  isWorking: boolean;
  onLeave: boolean;
  isWorkingAnotherSite: boolean;
  isWorkingAt: boolean;
  isWorkingAtNew: boolean;
  isWorkingAtSiteName: string;
  workingHours?: {
    start: string;
    finish: string;
  };
  inactiveRates: Record<string, RuleType[]>;
  standardBreaks: StandardBreakType;
  availStart: number;
  availFinish: number;
  match: boolean;
  hasEmployeeID: boolean;
  hasFutureRosters: boolean;
  mfaEnabled: boolean;
  employeeId: string | null;
  maxWeeklyHours?: string | number | null;
  sitesManager?: ManagerSchema[];
  sitesManagerAssistant?: ManagerSchema[];
  employmentStart?: string | null;
};

export interface FixedDayType {
  start: number;
  finish: number;
  break: number;
  siteId: number;
  positionId: number;
  originalDayToWork?: boolean;
  /** front-end only */
  hours: number;
  isSelected: boolean;
}

export type FixedDaysType = Record<string, FixedDayType>;
export interface FixedEmploymentDataType {
  annualSalary: number;
  defaultBreak: number;
  fixedRuleID: string;
  automaticallyAdd: boolean;
  days: FixedDaysType;
  totalHours: number /** front-end only */;
  totalHoursBySite: Record<number, number> /** front-end only */;
  weeklySalary: number /** front-end only */;
  weeklySalaryBySite: Record<number, number> /** front-end only */;
  times: number /** front-end only: how many times this employee appears on the week. */;
}

export interface DocumentsStaffInfoType {
  id: number;
  staffName: string;
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth?: string;
  mobileNumber: string;
  numberNational?: string;
  numberCountryCode: string;
}

export type AnniversaryType = {
  avatar: string | null;
  date: string;
  firstName: string;
  lastName: string;
  staffId: number;
  type: 'birthDay' | 'employmentStart';
};
