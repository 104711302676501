import { Toast } from 'react-bootstrap';
import { AlertCircleIcon, CheckboxMarkedCircleIcon, MessageAlertIcon } from 'components/icons';
import { Variant } from 'react-bootstrap/types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import style from './style.module.scss';
import { getErrorMessage } from 'helpers/get-error-message';

export interface AddToastProps {
  title: string;
  body?: string;
  variant?: Variant;
}
interface ToastState {
  toasts: (AddToastProps & { id: number })[];
  addToast: (props: AddToastProps) => void;
  removeToast: (id: number) => void;
  clear: () => void;
}
let id = 0;
export const useToast = create<ToastState, [['zustand/immer', never]]>(
  immer(set => ({
    toasts: [],
    clear: () =>
      set(state => {
        state.toasts = [];
      }),
    removeToast: (id: number) =>
      set(state => {
        state.toasts = state.toasts.filter(toast => toast.id !== id);
      }),
    addToast: props =>
      set(state => {
        state.toasts.push({ variant: 'primary', ...props, id: id++ });
      })
  }))
);

export function Toasts() {
  const { removeToast, toasts } = useToast();

  const onClose = (id: number) => () => {
    removeToast(id);
  };

  return toasts.length > 0 ? (
    // <ToastContainer position={'bottom-end'} className={`${style.toasts} pb-5 pe-5 position-fixed`}>
    //   {toasts.map(toast => (
    //     <CustomToast key={toast.id} onClose={onClose(toast.id)} {...toast} />
    //   ))}
    // </ToastContainer>
    <div className={`${style.toasts}`}>
      {toasts.map((toast, i) => (
        <CustomToast key={toast.id} index={i} onClose={onClose(toast.id)} {...toast} />
      ))}
    </div>
  ) : null;
}

function ToastIcon({ variant }: Pick<AddToastProps, 'variant'>) {
  switch (variant) {
    case 'success':
    case 'primary':
    case 'secondary':
      return <CheckboxMarkedCircleIcon size={1.2} className={`text-${variant} me-2`} />;
    case 'warning':
    case 'info':
    case 'light':
    case 'dark':
      return <MessageAlertIcon size={1.2} className={`text-${variant === 'light' ? 'text-light' : variant} me-2`} />;
    case 'danger':
      return <AlertCircleIcon size={1.2} className={`text-${variant} me-2`} />;
  }

  return null;
}

interface CustomToastProps {
  onClose: () => void;
  index: number;
}
function CustomToast({ title, body, variant, onClose, index }: AddToastProps & CustomToastProps) {
  const { toasts } = useToast();
  const offset = `${index * 3 + 10}px`;
  return (
    <Toast bg={variant} delay={5000} onClose={onClose} autohide style={{ bottom: offset, right: offset }}>
      <Toast.Header>
        <ToastIcon variant={variant} />
        <strong className="me-auto fs-4">{title}</strong>
      </Toast.Header>
      {body && <Toast.Body className={`${variant === 'light' ? 'text-light' : 'text-white'} fs-4`}>{index === toasts.length - 1 ? body : ' - '}</Toast.Body>}
    </Toast>
  );
}

export const useToastError = () => {
  const { addToast } = useToast();

  return (e: unknown) => {
    const body = getErrorMessage(e);

    addToast({
      title: 'Error',
      body,
      variant: 'danger'
    });
  };
};
