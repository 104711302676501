import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';
import { PERMISSION, Result, StaffType, UserType } from '@types';
import { queryClient } from 'api';
import { produce } from 'immer';

export const queryFnUser = (): Promise<UserType> => axios.get('/user');

export function useUser(options?: Omit<UseQueryOptions<UserType>, 'queryFn' | 'queryKey'>) {
  return useQuery<UserType>({
    queryKey: ['/user'],
    queryFn: queryFnUser,
    ...options
  });
}

export function useIsTrialAccount() {
  const { data } = useUser();
  const userInfo = data?.userInfo;
  return userInfo?.isBillingSaved === false && userInfo?.isTrialExp === false;
}

/**
 * If 'user' is passed, then test it. Otherwise, test the current logged user.
 * @param user
 */
export const useIsAdmin = (user?: UserType | UserType['userInfo'] | StaffType) => {
  const currentUser = useUser().data;

  return isAdmin(user ?? currentUser);
};

export function isAdmin(user: UserType | UserType['userInfo'] | StaffType | undefined) {
  if (!user) return false;

  if ('userInfo' in user) return user.userInfo.permission === PERMISSION.ADMIN;
  else return user.permission === PERMISSION.ADMIN;
}

/**
 * If 'user' is passed in, then test it. Otherwise, test the current logged user.
 * @param user
 */
export const useIsOwner = (user?: UserType | UserType['userInfo'] | StaffType) => {
  const currentUser = useUser().data;

  return isOwner(user ?? currentUser);
};

export function isOwner(user: UserType | UserType['userInfo'] | StaffType | undefined) {
  if (!user) return false;

  const currentUser = queryClient.getQueryData<UserType>(['/user']);

  if ('userInfo' in user) return user.userInfo.accountId === user.userInfo.uid;
  else if ('accountId' in user) return user.accountId === user.uid;
  else return user.id === currentUser?.userInfo.accountId;
}

export function useResetFirstLoginMutation() {
  const { data: dataUser } = useUser();
  const userId = dataUser?.userInfo.uid;
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => axios.post('/user/reset-first-login', { userId }),
    onSuccess() {
      queryClient.setQueryData<Result<UserType>>(
        ['/user'],
        produce(draft => {
          if (draft) {
            draft.result.userInfo.isFirstLogin = false;
          }
        })
      );
    }
  });
}
