import { experimental_extendTheme as extendTheme } from '@mui/material/styles';

export const calendarMedium = extendTheme({
  components: {
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          width: '240px',
          '&.payroll': {
            '.MuiDayCalendar-weekDayLabel': {
              margin: 0
            }
          }
        }
      }
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: {
          fontFamily: 'Lato, Arial, Helvetica Neue, Helvetica, sans-serif',
          fontSize: 'var(--bs-body-fs)',
          color: 'var(--bs-gray-400)',
          width: '30px',
          height: '30px'
        },
        slideTransition: {
          minHeight: '190px'
        }
      }
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontFamily: 'Arial, Helvetica Neue, Helvetica, sans-serif',
          color: 'var(--bs-gray-500)',
          fontSize: '1.3rem',
          width: '30px',
          height: '30px',
          '&.MuiPickersDay-dayOutsideMonth': {
            color: 'var(--bs-gray-400)'
          },
          '&.MuiPickersDay-today': {
            borderColor: 'var(--bs-gray-400)'
          }
        }
      }
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          color: 'var(--bs-gray-500)',
          paddingLeft: '1rem',
          paddingRight: '0'
        },
        labelContainer: {
          fontFamily: 'Lato, Arial, Helvetica Neue, Helvetica, sans-serif',
          fontSize: 'var(--bs-body-fs)',
          fontWeight: 'bold'
        }
      }
    }
  }
});

export const calendarSmall = extendTheme({
  components: {
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          width: '180px'
        }
      }
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekDayLabel: {
          fontFamily: 'Lato, Arial, Helvetica Neue, Helvetica, sans-serif',
          fontSize: '1.1rem',
          color: 'var(--bs-gray-400)',
          width: '20px',
          height: '20px'
        },
        slideTransition: {
          minHeight: '140px'
        }
      }
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          fontFamily: 'Arial, Helvetica Neue, Helvetica, sans-serif',
          color: 'var(--bs-gray-500)',
          fontSize: '1.1rem',
          width: '20px',
          height: '20px',
          '&.MuiPickersDay-dayOutsideMonth': {
            color: 'var(--bs-gray-400)'
          },
          '&.MuiPickersDay-today': {
            borderColor: 'var(--bs-gray-400)'
          }
        }
      }
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          paddingLeft: '1rem',
          paddingRight: '0'
        },
        labelContainer: {
          fontFamily: 'Lato, Arial, Helvetica Neue, Helvetica, sans-serif',
          fontSize: 'var(--bs-body-fs)',
          fontWeight: 'bold'
        }
      }
    }
  }
});
