import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Result, RosterSettingsType } from '@types';

export const queryFnSettings = (): Promise<Result<RosterSettingsType>> => axios.get('/dashboard/settings/roster-settings');

export function useRosterSettings() {
  return useQuery<Result<RosterSettingsType>>({
    queryKey: ['/dashboard/settings/roster-settings'],
    queryFn: queryFnSettings
  });
}
