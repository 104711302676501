import { QueryClient } from '@tanstack/react-query';
import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { queryFnUser } from 'hooks/api/user';
import { queryFnSettings } from 'hooks/api/roster-settings';
import axios from 'axios';
import { PERMISSION, UserInfoType, UserType } from '@types';
import { AccountContext } from 'components/login/modal/tabs/login';
import { clearSessionID, getSessionID, setAuthIdLoginFlow, setSessionID } from 'helpers/session-identifier';
import { disallowUser } from 'components/allow-user';

export function mainLoader(queryClient: QueryClient) {
  return async function ({ request }: LoaderFunctionArgs) {
    const url = new URL(request.url);
    const authid = url.searchParams.get('authid');

    let user: UserInfoType | undefined;

    if (!url.pathname.includes('logout') && !url.pathname.includes('login')) {
      let searchParams = url.searchParams.toString();
      if (searchParams) searchParams = '?' + searchParams;
      sessionStorage.setItem('redirectAfterLogin', url.pathname + searchParams);
    }

    if (authid) {
      setAuthIdLoginFlow();
      try {
        const response = await queryClient.fetchQuery({ queryKey: ['/user'], queryFn: (): Promise<AccountContext> => axios.post('/auth/redirect', { authid }) });
        user = response.userInfo as UserInfoType;

        setSessionID(response.sid);
      } catch (e) {
        clearSessionID();
        return redirect('/login');
      }
    }

    const enabled = getSessionID() !== null;

    if (!enabled) {
      clearSessionID();
      return redirect('/login');
    }

    try {
      // console.log('mainLoader', 'fetching initial data');
      user = user ?? queryClient.getQueryData<UserType>(['/user'])?.userInfo;

      if (!user) {
        const response = await queryClient.fetchQuery({ queryKey: ['/user'], queryFn: queryFnUser });
        user = response.userInfo;
      }

      if (disallowUser(user, [PERMISSION.STAFF]) && !queryClient.getQueryData(['/dashboard/settings/roster-settings']))
        await queryClient.fetchQuery({ queryKey: ['/dashboard/settings/roster-settings'], queryFn: queryFnSettings });
    } catch (e) {
      clearSessionID();
      return redirect('/login');
    }

    return null;
  };
}

export function permissionLoader(queryClient: QueryClient, allowedPermissions: PERMISSION[], redirectUrl: string = '/') {
  return async function ({ request }: LoaderFunctionArgs) {
    const user = await queryClient.fetchQuery({ queryKey: ['/user'], queryFn: queryFnUser });
    if (user && ![PERMISSION.ADMIN, ...allowedPermissions].includes(user.userInfo.permission)) {
      return redirect(redirectUrl);
    }

    return null;
  };
}

export function redirectLoader(queryClient: QueryClient) {
  return async function ({ request }: LoaderFunctionArgs) {
    const user = await queryClient.fetchQuery({ queryKey: ['/user'], queryFn: queryFnUser });
    // console.log('redirect loader');
    if (user && user.userInfo.permission === PERMISSION.STAFF) {
      return redirect('/my-calendar');
    }

    return null;
  };
}

export function invalidateQueriesLoader(queryClient: QueryClient, queriesKeys: string[][], allowedPermissions: PERMISSION[]) {
  return async function ({ request }: LoaderFunctionArgs) {
    const user = await queryClient.fetchQuery({ queryKey: ['/user'], queryFn: queryFnUser });
    if (user && ![PERMISSION.ADMIN, ...allowedPermissions].includes(user.userInfo.permission)) {
      return redirect('/');
    }

    for (let queryKey of queriesKeys) {
      // console.log('Invalidating query for:', queryKey);
      queryClient.invalidateQueries({ queryKey, refetchType: 'all' });
    }

    return null;
  };
}
