export function loopAlphabetically(object: Record<string, unknown>) {
  return Object.keys(object).sort();
}

export const sortAlphabetically = (field: string) => (a: any, b: any) => {
  if (field in a && field in b) {
    if (a[field] < b[field]) return -1;
    if (a[field] > b[field]) return 1;
    return 0;
  }

  return 0;
};
