import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { Result, SiteType } from '@types';
import { useMemo } from 'react';
import { sortAlphabetically } from 'helpers/loop-alphabetically';

export const queryFnSites = (): Promise<SiteType[]> => axios.get('/sites');
export const queryFnAllSites = (): Promise<SiteType[]> => axios.get('/sites/all');

export function useSites() {
  return useQuery<SiteType[]>({
    queryKey: ['/sites'],
    queryFn: queryFnSites
  });
}

export function useAllSites() {
  return useQuery<SiteType[]>({
    queryKey: ['/sites/all'],
    queryFn: queryFnAllSites
  });
}

export function useHeadOffice() {
  return useQuery({
    queryKey: ['/sites/head-office'],
    queryFn: (): Promise<SiteType> => axios.get('/sites/head-office')
  });
}

// export type RosterSitesReturn = Record<string, StaffSchema['sites'][number]>;
export type RosterSitesReturn = Record<string, SiteType>;
interface RosterSitesProps {
  returnAsArray?: boolean;
  companyId?: number;
  full?: 1 | 0;
}
export function useRosterSites(props: RosterSitesProps = {}) {
  const { returnAsArray, companyId, full = 1 } = props;

  const params = new URLSearchParams();
  if (companyId) params.append('companyId', companyId.toString());
  if (full) params.append('full', full.toString());
  const paramsStr = params.toString();

  const urlSites = `/schedule/sites${paramsStr ? '?' + paramsStr : ''}`;
  const queryKey = useMemo(() => [urlSites], [urlSites]);
  const query = useQuery({
    queryKey,
    queryFn: (): Promise<Result<RosterSitesReturn>> => axios.get(urlSites),
    staleTime: 0
  });
  const array = useMemo(() => (returnAsArray && query.data ? Object.values(query.data.result).sort(sortAlphabetically('name')) : undefined), [query.data, returnAsArray]);

  return { ...query, array, queryKey };
}
